import React from 'react';
import "./footer.css";

export default function Footer() {
  return (
    <footer>
        <span>Maité Dávila © 2023</span>
        <p>Desarrollado por 渦 Uzu Software by B. Ksiazek</p>
    </footer>
  )
}
